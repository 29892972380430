<template>
  <div>
    <div>
      <div class="card card-top card-top-primary">
        <div class="card-header card-header-flex">
          <div class="d-flex flex-column justify-content-center mr-auto">
            <h5 class="mb-0">
              <strong>New Customer</strong>
            </h5>
          </div>
          <!-- Add actions here if needed -->
        </div>
        <div class="card-body">
          <a-form
            :model="formState"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :scroll-to-first-error="true"
            @finish="handleFinish"
            @finishFailed="handleFinishFailed"
          >
            <div class="row">
              <div class="col-md-8">
                <a-form-item label="Customer Type" name="customerType">
                  <a-radio-group v-model:value="formState.customerType">
                    <a-radio value="business">
                      Business
                    </a-radio>
                    <a-radio value="individual">
                      Individual
                    </a-radio>
                    <a-radio value="agent">
                      Agent
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <a-form-item label="Full Name" name="fullName">
                  <a-input
                    placeholder="Enter Full Name"
                    v-model:value="formState.fullName"
                    @blur="formState.displayName = formState.fullName"
                    allow-clear
                  />
                </a-form-item>
                <a-form-item label="Company Name" name="companyName">
                  <a-input
                    placeholder="Enter Company Name"
                    v-model:value="formState.companyName"
                    allow-clear
                  />
                </a-form-item>
                <a-form-item label="Display Name" name="displayName">
                  <a-auto-complete
                    placeholder="Enter Display Name"
                    v-model:value="formState.displayName"
                    :options="displayNameOptions"
                  />
                </a-form-item>
                <a-form-item label="Email" name="email">
                  <a-input
                    placeholder="Enter Email Address"
                    v-model:value="formState.email"
                    allow-clear
                  />
                </a-form-item>
              </div>
              <div class="col-md-6">
                <a-form-item label="Opening Balance" name="openingBalance">
                  <a-input-number
                    placeholder="Enter Opening Balance"
                    v-model:value="formState.openingBalance"
                    allow-clear
                    :style="{ width: '100%' }"
                    :default-value="0"
                  />
                </a-form-item>
                <a-form-item label="Mobile" name="mobile">
                  <a-input
                    placeholder="Enter Mobile # (10 digit)"
                    type="number"
                    v-model:value="formState.mobile"
                    allow-clear
                  />
                </a-form-item>
                <a-form-item label="PAN" name="pan">
                  <a-input placeholder="Enter PAN #" v-model:value="formState.pan" allow-clear />
                </a-form-item>
                <a-form-item label="GSTIN" name="gstin">
                  <a-input placeholder="Enter GSTIN" v-model:value="formState.gstin" allow-clear />
                </a-form-item>
              </div>
            </div>
            <a-divider />
            <div>
              <a-tabs :default-active-key="1" class="vb-tabs-bordered mb-2" :animated="false">
                <!-- Address TAB -->
                <a-tab-pane key="1">
                  <template #tab>
                    <span class="p-4">
                      Address
                    </span>
                  </template>
                  <div>
                    <InputFormAddress v-model:address="formState.primaryAddress" />
                  </div>
                </a-tab-pane>

                <!-- Social TAB -->
                <a-tab-pane key="2">
                  <template #tab>
                    <span class="p-4">
                      Social
                    </span>
                  </template>
                  <div>
                    <div class="row card-body">
                      <div class="col-md-6">
                        <InputFormSocial v-model:social="formState.social" />
                      </div>
                    </div>
                  </div>
                </a-tab-pane>

                <!-- Other Details TAB -->
                <a-tab-pane key="3">
                  <template #tab>
                    <span class="p-4">
                      Other Details
                    </span>
                  </template>
                  <div>
                    <div class="row">
                      <div class="col-md-6">
                        <a-form-item label="Website" name="website">
                          <a-input
                            placeholder="Enter Website URL"
                            v-model:value="formState.website"
                            allow-clear
                          />
                        </a-form-item>
                        <a-form-item label="Birthday" name="birthday">
                          <a-date-picker
                            v-model:value="formState.birthday"
                            value-format="YYYY-MM-DDTHH:mm:ssZ"
                            allow-clear
                          />
                        </a-form-item>
                      </div>
                    </div>
                  </div>
                </a-tab-pane>

                <!-- Contact Persons TAB -->
                <a-tab-pane key="4">
                  <template #tab>
                    <span class="p-4">
                      Contact Persons
                    </span>
                  </template>
                  <div class="card-body">
                    <a-button class="mb-4" @click="isAddContactPersonModalVisible = true">
                      Add Contact Person
                    </a-button>
                    <InputFormContactPersonModal
                      v-model:isAddContactPersonModalVisible="isAddContactPersonModalVisible"
                      @onAddContactPerson="handleAddContactPerson"
                    />
                    <a-table
                      :columns="contactPersonColumns"
                      :data-source="formState.contactPersons"
                      :row-key="(rec, idx) => idx"
                      :bordered="true"
                    >
                      <template #actions="{index}">
                        <div>
                          <a-popconfirm
                            title="Sure to delete?"
                            @confirm="() => formState.contactPersons.splice(index, 1)"
                          >
                            <a>
                              <i class="fe fe-x-circle" />
                            </a>
                          </a-popconfirm>
                        </div>
                      </template>
                    </a-table>
                  </div>
                </a-tab-pane>
              </a-tabs>
              <div>
                <a-button html-type="submit" :loading="loading" class="btn btn-primary px-5 mr-3">
                  Save
                </a-button>
                <a-button class="btn btn-light px-5" @click.prevent="$router.go(-1)">
                  Cancel
                </a-button>
              </div>
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { computed, defineComponent, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import InputFormSocial from '@/components/InputForms/InputFormSocial.vue'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import InputFormContactPersonModal from '@/components/InputForms/InputFormContactPersonModal.vue'
import { IContactPerson } from '@/types/interfaces/IContactPerson'
import { CustomerFormData } from '@/types/appContracts/CustomerFormData'
import { CustomerTypes } from '@/types/enums/CustomerTypes'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}

const contactPersonColumns = [
  {
    title: 'Full Name',
    dataIndex: 'cpName',
    key: 'cpName',
  },
  {
    title: 'Email',
    dataIndex: 'cpEmail',
    ellipsis: true,
    key: 'cpEmail',
  },
  {
    title: 'Designation',
    dataIndex: 'cpDesignation',
    key: 'cpDesignation',
  },
  {
    title: 'Mobile',
    dataIndex: 'cpMobile',
    key: 'cpMobile',
  },
  {
    title: 'Notes',
    dataIndex: 'cpNotes',
    key: 'cpNotes',
    ellipsis: true,
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  components: {
    InputFormSocial,
    InputFormAddress,
    InputFormContactPersonModal,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
    // const rupee = '&#8377'

    const isAddContactPersonModalVisible = ref(false)

    const formState = reactive<CustomerFormData>({
      customerType: CustomerTypes.Individual,
      fullName: null,
      companyName: null,
      displayName: null,
      email: null,
      mobile: null,
      pan: null,
      gstin: null,
      primaryAddress: {
        country: 'India',
        attention: null,
        city: null,
        state: null,
        address: null,
        pincode: null,
      },
      openingBalance: 0,
      website: null,
      contactPersons: [],
      social: { linkedin: null, facebook: null, twitter: null },
      birthday: null,
      photoUrl: null,
    })

    const rules = {
      displayName: [
        { required: true, message: 'Please enter customer display name', trigger: 'blur' },
      ],
      email: [{ type: 'email', message: 'Please input a valid email address', trigger: 'blur' }],
      gstin: [{ type: 'string', len: 15, message: 'Please enter valid gstin', trigger: 'blur' }],
    }

    const displayNameOptions = computed(() => {
      let displayNameArr: Array<Object> = []

      if (formState.fullName) {
        displayNameArr.push({ value: formState.fullName })
      }

      if (formState.companyName) {
        displayNameArr.push({ value: formState.companyName })
      }

      return displayNameArr
    })

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<CustomerFormData> = {
        action: DocumentActions.CREATE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      await store.dispatch(`customers/${actions.CustomersAction.SET_OR_DELETE_CUSTOMER}`, docOperation)
      loading.value = false
      router.push({ name: 'customers' })
    }

    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const handleAddContactPerson = (contactPerson: IContactPerson) => {
      formState.contactPersons.push(contactPerson)
    }

    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      loading,
      handleFinish,
      handleFinishFailed,
      isAddContactPersonModalVisible,
      handleAddContactPerson,
      contactPersonColumns,
      displayNameOptions,
    }
  },
})
</script>
