
import { useRouter } from 'vue-router'
import { computed, defineComponent, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import InputFormSocial from '@/components/InputForms/InputFormSocial.vue'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import InputFormContactPersonModal from '@/components/InputForms/InputFormContactPersonModal.vue'
import { IContactPerson } from '@/types/interfaces/IContactPerson'
import { CustomerFormData } from '@/types/appContracts/CustomerFormData'
import { CustomerTypes } from '@/types/enums/CustomerTypes'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}

const contactPersonColumns = [
  {
    title: 'Full Name',
    dataIndex: 'cpName',
    key: 'cpName',
  },
  {
    title: 'Email',
    dataIndex: 'cpEmail',
    ellipsis: true,
    key: 'cpEmail',
  },
  {
    title: 'Designation',
    dataIndex: 'cpDesignation',
    key: 'cpDesignation',
  },
  {
    title: 'Mobile',
    dataIndex: 'cpMobile',
    key: 'cpMobile',
  },
  {
    title: 'Notes',
    dataIndex: 'cpNotes',
    key: 'cpNotes',
    ellipsis: true,
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

export default defineComponent({
  components: {
    InputFormSocial,
    InputFormAddress,
    InputFormContactPersonModal,
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
    // const rupee = '&#8377'

    const isAddContactPersonModalVisible = ref(false)

    const formState = reactive<CustomerFormData>({
      customerType: CustomerTypes.Individual,
      fullName: null,
      companyName: null,
      displayName: null,
      email: null,
      mobile: null,
      pan: null,
      gstin: null,
      primaryAddress: {
        country: 'India',
        attention: null,
        city: null,
        state: null,
        address: null,
        pincode: null,
      },
      openingBalance: 0,
      website: null,
      contactPersons: [],
      social: { linkedin: null, facebook: null, twitter: null },
      birthday: null,
      photoUrl: null,
    })

    const rules = {
      displayName: [
        { required: true, message: 'Please enter customer display name', trigger: 'blur' },
      ],
      email: [{ type: 'email', message: 'Please input a valid email address', trigger: 'blur' }],
      gstin: [{ type: 'string', len: 15, message: 'Please enter valid gstin', trigger: 'blur' }],
    }

    const displayNameOptions = computed(() => {
      let displayNameArr: Array<Object> = []

      if (formState.fullName) {
        displayNameArr.push({ value: formState.fullName })
      }

      if (formState.companyName) {
        displayNameArr.push({ value: formState.companyName })
      }

      return displayNameArr
    })

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<CustomerFormData> = {
        action: DocumentActions.CREATE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      await store.dispatch(`customers/${actions.CustomersAction.SET_OR_DELETE_CUSTOMER}`, docOperation)
      loading.value = false
      router.push({ name: 'customers' })
    }

    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const handleAddContactPerson = (contactPerson: IContactPerson) => {
      formState.contactPersons.push(contactPerson)
    }

    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      loading,
      handleFinish,
      handleFinishFailed,
      isAddContactPersonModalVisible,
      handleAddContactPerson,
      contactPersonColumns,
      displayNameOptions,
    }
  },
})
